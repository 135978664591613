<template>
    <div class="DeviceInfoList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="deviceForm" ref="deviceForm">
                    <el-form-item label="仪器名称">
                        <el-input v-model="deviceForm.deviceName" placeholder="请输入仪器名称查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器条码">
                        <el-input v-model="deviceForm.deviceCode" placeholder="请输入仪器条码查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器编号">
                        <el-input v-model="deviceForm.deviceNum" placeholder="请输入仪器编号查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器分类">
                        <el-select placeholder="请选择仪器仪表分类" v-model="deviceForm.deviceOneClass">
                            <el-option v-for="(item, index) in deviceOneClassList" :key="index" :label="item.subClassDesc"
                                :value="item.subClassCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button class="btn" icon="el-icon-circle-plus-outline" @click="$router.push({ name: 'deviceInfo-create' })">新增</el-button>
                    <!-- <el-button type="primary" @click="$router.push({ name: 'deviceInfo-chgLocal' })" style="margin-left: 10px;">位置更换</el-button> -->
                    <input type="file" ref="import" style="display: none" @change="uploadIn($event)" />
                    <el-button type="warning" @click="handleIn" icon="el-icon-download" 
                        style="margin-left: 10px;">导入</el-button>
                    <el-button  type="primary" @click="handleDown" icon="el-icon-upload2" >导出</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="deviceList" border  style="width: 100%; font-size: 13px;" :cell-style="bgColor"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="deviceOneClassDesc" label="仪器分类" align="center"> </el-table-column>
                <el-table-column prop="deviceName" label="仪器名称" align="center"> </el-table-column>
                <el-table-column prop="deviceRealName" label="实际名称" align="center">
                </el-table-column>
                <el-table-column prop="deviceCode" label="仪器条码" align="center">
                </el-table-column>
                <el-table-column prop="deviceType" label="仪器型号" align="center">
                </el-table-column>
                <el-table-column prop="deviceNum" label="仪器编号" align="center">
                </el-table-column>
                <el-table-column prop="purchaseDate" label="购置日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="manufacturer" label="生产厂家" align="center" width="160">
                </el-table-column>
                <el-table-column prop="whCodeName" label="所在库房" align="center" >
                </el-table-column>
                <el-table-column prop="location" label="保存地点" align="center" width="160">
                </el-table-column>
                <!-- <el-table-column prop="remark" label="备注" align="center">
                </el-table-column> -->

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                        <el-button id="del" type="text" size="mini" title="装备附件"
                            @click="handleAnnex(scope.row)">装备附件</el-button>

                        <el-button type="text" size="mini" title="删除" :class="delColor"
                            @click="handleDel(scope.row)"><span>删除</span></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="deviceForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="deviceForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


            <!-- 绑定仪器仪表的条码 -->
            <!-- <el-dialog title="条码绑定" :visible.sync="bindCodeView" width="500px" :before-close="closeBindCode"
                :close-on-click-modal="false">
                <el-form :inline="true" :model="formDevice" class="demo-form-inline" size="small">
                    <el-form-item label="编号" v-show="deviceIdShow" required>
                        <el-input v-model="formDevice.id"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器条码" required>
                        <el-input v-model="formDevice.deviceCode" placeholder="请录入仪器条码" v-focus
                            @keydown.enter.native="courierNumEnterDown" @keyup.enter.native="courierNumEnterUp"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="bindDeviceCode">提交</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog> -->

            <!-- 删除 -->
            <el-dialog :visible.sync="delDeviceInfoView" width="400px" :before-close="closeDelInfo"
                :close-on-click-modal="false">
                <div class="delInfo"> 确定要删除该仪器仪表吗？</div>
                <el-form :model="delForm" :rules="DelRules" ref="delForm">
                    <!-- <el-form-item label="编号" v-show="!delDeviceInfoView" :label-width="formLabelWidth" required prop="id">
                        <el-input v-model="delForm.id"></el-input>
                    </el-form-item> -->
                    <el-form-item label="仪器名称" :label-width="formLabelWidth" required prop="deviceName">
                        <el-input v-model="delForm.deviceName" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="仪器条码" :label-width="formLabelWidth" required prop="deviceCode">
                        <el-input v-model="delForm.deviceCode" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="删除原因" :label-width="formLabelWidth" prop="deviceStatus">
                        <el-select placeholder="请选择仪器仪表分类" v-model="delForm.deviceStatus">
                            <el-option v-for="(item, index) in statusList" :key="index" :label="item.statusDese"
                                :value="item.status"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDelInfo('delForm')">取 消</el-button>
                    <el-button type="primary" @click="handledelInfo('delForm')">确 定</el-button>
                </div>
            </el-dialog>


        </el-card>
    </div>
</template>

<script>
import { searchDeviceData, scrapDevice, importExcel } from '@/api/device'
import { downloadExcel } from '@/api/download.js'
import { getDeviceClassByLevel } from '@/api/deviceClass.js'


export default {
    data() {
        return {
            deviceOneClassList: [],
            deviceList: [],
            deviceForm: {
                type: '01',
                deviceCode: '',
                deviceName: '',
                deviceType: '',
                deviceNum: '',
                deviceOneClass: '',
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            statusList: [{ status: '09', statusDese: '报废移除' }, { status: '10', statusDese: '借入归还' }, { status: '11', statusDese: '租赁移除' }],
            delForm: {  //删除设备
                id: '',
                deviceCode: '',
                deviceStatus: null
            },
            delDeviceInfoView: false,
            formLabelWidth: '80px',
            DelRules: {
                deviceStatus: [{ required: true, message: '请选择删除原因' }],
            },
            delColor: 'delColor'

        }
    },
    mounted() {

        this.loadDeviceOneClass()
        this.searchDeviceData()
    },
    methods: {

        handleSizeChange(val) {
            this.deviceForm.pageSize = val;
            this.deviceForm.pageNum = 1;
            this.searchDeviceData();
        },
        handleCurrentChange(val) {
            this.deviceForm.pageNum = val;
            this.searchDeviceData();
        },
        async loadDeviceOneClass() {
            const level = 1
            await getDeviceClassByLevel(level).then(res => {
                if (res.code === '000000') {
                    this.deviceOneClassList = res.t
                }

            })
        },

        //查询
        searchDeviceData() {
            searchDeviceData(this.deviceForm).then(res => {
                if (res.code === '000000') {
                    this.deviceList = res.t.list
                    this.total = res.t.total
                    let count = 1;
                    this.deviceList.forEach((item) => {
                        item.seq = count++
                        // item.userStatus ===1 ? (item.userStatus_text='启用') : (item.userStatus_text='停用')
                        // count= count +1;
                    })
                }

            })

        },

        //修改
        handleEdit({ id }) {
            this.$router.push({
                name: 'deviceInfo-edit',
                params: {
                    id
                }
            })
        },

        //详情
        handleDetail({ id }) {
            this.$router.push({
                name: 'deviceInfo-detail',
                params: {
                    id
                }
            })
        },
        handleAnnex({ id }) {
            this.$router.push({
                name: 'deviceInfo-deviceAnnex',
                params: {
                    did: id
                }
            })
        },
        //绑定仪器条码
        // bindCode(id) {

        //     this.bindCodeView = true
        //     this.formDevice.id = id
        // },

        // bindDeviceCode() {
        //     bindCode(this.formDevice).then(res => {
        //         if (res.code === '000000') {
        //             this.bindCodeView = false
        //             this.formDevice = {
        //                 id: '',
        //                 deviceCode: ''
        //             }
        //             this.$message.success("绑定成功！")
        //         } else {
        //             this.bindCodeView = false
        //             this.formDevice = {
        //                 id: '',
        //                 deviceCode: ''
        //             }

        //         }
        //         this.searchDeviceData()
        //     })
        // },

        // closeBindCode() {
        //     this.bindCodeView = false
        //     this.formDevice.id = ''
        // },

        // courierNumEnterDown() {
        //     this.keyDownTime = new Date().getTime()
        // },
        // courierNumEnterUp() {
        //     this.keyUpTime = new Date().getTime()
        //     if (this.keyUpTime - this.keyDownTime < 30) {
        //         this.bindDeviceCode()
        //         this.searchDeviceData()
        //     } else {
        //         this.bindDeviceCode()
        //         this.searchDeviceData()
        //     }
        // },

        //删除
        handleDel(row) {
            const { id, deviceCode,deviceName} = row
            this.delDeviceInfoView = true
            this.delForm.id = id
            this.delForm.deviceCode = deviceCode
            this.delForm.deviceName = deviceName
        },
        handledelInfo(delForm) {
            this.$refs[delForm].validate(valid => {
                if (valid) {
                    scrapDevice(this.delForm).then(res => {
                        if (res.code === '000000') {
                            this.$message.success('删除成功')
                        }
                        this.$refs.delForm.resetFields()
                        this.delDeviceInfoView = false
                        this.searchDeviceData()
                    })
                }
            })

        },
        closeDelInfo() {
            this.$refs.delForm.resetFields()
            this.delDeviceInfoView = false
        },

        //搜索
        handleSearch() {
            this.searchDeviceData(this.deviceForm);
        },
        //重置
        handleReset() {
            this.deviceForm = {
                type: '01',
                deviceName: '',
                deviceType: '',
                deviceNum: '',
                deviceOneClass: '',
                pageNum: 1,
                pageSize: 10
            }
            this.searchDeviceData(this.deviceForm);
        },

        //点击导入
        handleIn() {
            this.$refs.import.click();
        },
        //导入
        uploadIn(event) {
            let file = event.target.files; //获得文件
            for (let i = 0; i < file.length; i++) {
                //    上传类型判断
                let fileName = file[i].name;
                let idx = fileName.lastIndexOf(".");
                if (idx != -1) {
                    let ext = fileName.substr(idx + 1).toUpperCase();
                    ext = ext.toLowerCase();
                    if (ext !== "xls" && ext !== "xlsx") {
                        this.$message.warning('上传文件只能是excel文件!')
                        return;
                    } else {
                        this.importFile = file[i];
                    }
                }
            }
            let formData = new FormData(); //数据
            formData.append("file", this.importFile);
            importExcel(formData)
                .then((res) => {
                    if (res.code === '000000') {
                        this.searchDeviceData()
                        this.$message.success("导入成功");
                    } else {
                        this.searchDeviceData()
                        this.$message.success("导入失败");
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
        },

        handleDown() {
            this.$fullScreenLoading.show("导出中...");
            
            downloadExcel(this.deviceForm,'/device/downloadExcel').then(res => {
                setTimeout(() => {
                    let blob = new Blob([res.data], {
                        type: "application/octet-stream",
                    });
                    // 创建一个a标签
                    var tempLink = document.createElement("a");
                    const fileNames = res.headers['content-disposition']
                    console.log(fileNames)
                    const regFileNames = fileNames.match(/=(.*)$/)[1]
                    console.log(regFileNames)
                    // let filename = "自定义文件名称"+ '.xls'
                    // 将blob对象转为一个URL
                    var blobURL = window.URL.createObjectURL(blob);

                    // 隐藏a标签
                    tempLink.style.display = "none";
                    // 设置a标签的href属性为blob对象转化的URL
                    tempLink.href = blobURL;
                    tempLink.download = decodeURI(regFileNames)// 下载后文件名
                    // 将a标签添加到body当中
                    document.body.appendChild(tempLink);
                    // 启动下载
                    tempLink.click();
                    // 下载完毕删除a标签
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                    this.$fullScreenLoading.hide();
                    this.$message({
                    message: "导出成功~",
                    type: "success",
                });
                }, 500)
                

            })
        },

        bgColor({ row }) {
            let cellStyle = '';
            const { caution,  deviceStatus } = row
             if(caution === '02' || caution === '12' || caution === '22' || deviceStatus==='01' || deviceStatus==='03'){
                cellStyle = 'background-color: rgb(237, 83, 83);color: #FFF'
            }else if(caution==='01' || caution==='11' || caution === '21' || deviceStatus==='02' || deviceStatus==='04'){
                cellStyle = 'background-color: rgb(234, 214, 125);color: #000;'
            }else{
                cellStyle = 'background-color: #FFF;color: #000;' 
            }
            
            return cellStyle;

        },
    }
}

</script>


<style lang="scss" scoped>
.DeviceInfoList {

    .el-form-item,
    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: #ff6600;
        
    }

    .delOtherColor {
        color: #300202 !important; //409EFF
    }

    .delInfo {
        font-size: 16px;
        text-align: left;
        margin-bottom: 15px;
    }

}
</style>